import React from "react"

const QuandaryValuesApproach = () => {
  return (
    <>
      <div className="text-center">
        <div className="qvalues ">
          <div className="peaks row justify-content-center peaks-wrapper p-3">
            <div className="rounded col-lg-2 col-sm-4 mb-2">
              <h1 className="peaks-blue font-weight-bold text-center">P</h1>
              <p className="h5 mt-0 text-white font-weight-bold">Perspective</p>
              <p className>
                Gain a clear overview of your specific business needs.
              </p>
            </div>
            <div className="rounded col-lg-2 col-sm-4 mb-2">
              <h1 className="peaks-blue font-weight-bold text-center">E</h1>
              <p className="h5 mt-0 text-white font-weight-bold">Expertise</p>
              <p>
                Use industry knowledge to design custom system improvements.
              </p>
            </div>
            <div className="rounded col-lg-2 col-sm-4 mb-2">
              <h1 className="peaks-blue font-weight-bold text-center">A</h1>
              <p className="h5 mt-0 text-white font-weight-bold">Adoption</p>
              <p>Ensure new systems are quickly and easily adopted by staff.</p>
            </div>
            <div className="rounded col-lg-2 col-sm-4 mb-2">
              <h1 className="peaks-blue font-weight-bold text-center">K</h1>
              <p className="h5 mt-0 text-white font-weight-bold">Knowledge</p>
              <p>
                Share collective insights aimed at positioning you above
                competitors.
              </p>
            </div>
            <div className="rounded col-lg-2 col-sm-4 mb-2">
              <h1 className="peaks-blue font-weight-bold text-center">S</h1>
              <p className="h5 mt-0 text-white font-weight-bold">Support</p>
              <p>
                Provide rapid responses for clear, consistent communication.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QuandaryValuesApproach
