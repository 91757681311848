import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const SoftwareAssetMgmt = () => {
  return (
    <>
      <div className="text-center">
        <div className="qvalues ">
          <div className="peaks row justify-content-center peaks-wrapper">
            {/* <div className="sam col-12 col-sm-6 col-md-4 col-lg-3 mb-2"> */}
            <div class="sam col-12 col-md-3 col-lg-3 mb-2">
              <StaticImage
                placeholder="blurred"
                className="logo-static-img img-fluid"
                imgClassName="img-fluid w-100 h-100"
                src="../../images/new-images/Budget-Icon.png"
                alt="budget"
                layout="constrained"
                width={162}
                loading="lazy"
              />
              <p className="h5 mt-0 text-black font-weight-bold">
                Maximize Your Software Budget
              </p>
              <p className>
                Unused tools Cut IT waste by 30%, giving your team more
                resources for high-value projects.
              </p>
            </div>
            <div class="sam col-12 col-md-3 col-lg-3 mb-2">
              <StaticImage
                placeholder="blurred"
                className="logo-static-img img-fluid"
                imgClassName="img-fluid w-100 h-100"
                src="../../images/new-images/SMBs-Icon.png"
                alt="smbs"
                layout="constrained"
                width={162}
                loading="lazy"
              />
              <p className="h5 mt-0 text-black font-weight-bold">
                For SMBs with Complex Systems
              </p>
              <p>
                Bespoke service: optimize your tech stack at the department,
                processes, and culture level.
              </p>
            </div>
            <div class="sam col-12 col-md-3 col-lg-3 mb-2">
              <StaticImage
                placeholder="blurred"
                className="logo-static-img img-fluid"
                imgClassName="img-fluid w-100 h-100"
                src="../../images/new-images/Time-Icon.png"
                alt="time"
                layout="constrained"
                width={162}
                loading="lazy"
              />
              <p className="h5 mt-0 text-black font-weight-bold">
                Reclaim Your Time and Drive Business Growth
              </p>
              <p>
                We implement in weeks using low-cost, low-risk tools, focusing
                on high-impact changes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SoftwareAssetMgmt
