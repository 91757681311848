import React from "react"

import { graphql, useStaticQuery, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

import "./hero.css"

const TechStack = () => {
  // const { heroImage } = useStaticQuery(
  //   graphql`
  //     {
  //       heroImage: file(
  //         relativePath: { eq: "new-images/quandary-connect-banner.jpg" }
  //       ) {
  //         childImageSharp {
  //           gatsbyImageData(
  //             width: 1920
  //             layout: CONSTRAINED
  //             formats: [AUTO, WEBP, AVIF]
  //           )
  //         }
  //       }
  //     }
  //   `
  // )

  // const heroImageData = getImage(heroImage)

  // Use like this:
  // const bgImage = convertToBgImage(heroImageData)

  return (
    <>
      <div
        // Tag="div"
        // {...bgImage}
        // fluid={imageData}
        className="hero-banner tech-stack"
        // preserveStackingContext
        style={{ backgroundPosition: "top center" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 mr-auto">
              <div className="tech-stack-section pt-2 pb-5">
                <h3 className="mb-3 text-left-mobile text-cyan ">
                  Simplify Software. <br />
                  Cut Costs.
                  <br /> Eliminate redundancies.
                </h3>
                <p className="">
                  We analyze your tech stack, consolidating your licenses <br />
                  and IT expenses.&nbsp;<i>No spreadsheets required.</i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TechStack
