import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

function ScoreApp() {
  const [loading, setLoading] = useState(true)

  useEffect(function createStyle() {
    // Create a new style element
    const style = document.createElement("style");
    style.innerHTML = `
      .sa-full>iframe {
        padding-top: 3.5rem;

        @media screen and (min-width: 640px) {
            padding-top: 6rem;
        }
      }
    `

    // Append the style element to the document's head
    document.body.appendChild(style)

    return () => {
      // Cleanup: Remove the style element on unmount
      document.body.removeChild(style)
    }
  }, []);

  useEffect(() => {
    // Load ScoreApp script
    const scoreScript = document.createElement("script")
    scoreScript.src =
      "https://static.scoreapp.com/js/integration/v1/embedding.js?v=NSJeRt"
    scoreScript.async = true
    scoreScript.onload = () => {
      setLoading(false)
      setTimeout(() => {
        const iframe = document.querySelector("iframe")
        if (iframe) {
          iframe.classList.add("event-ai-section")
        }
      }, 1000) // Adjust the timeout as needed
    }

    const scoreAppContainer = document.getElementById("score-app")
    if (!scoreAppContainer) return

    scoreAppContainer.appendChild(scoreScript)

    // Load Voiceflow chat script
    const voiceflowScript = document.createElement("script")
    voiceflowScript.type = "text/javascript"
    voiceflowScript.src = "https://cdn.voiceflow.com/widget/bundle.mjs"
    voiceflowScript.onload = () => {
      window.voiceflow.chat.load({
        verify: { projectID: "6782ecab5b843c00ceda590c" },
        url: "https://general-runtime.voiceflow.com",
        versionID: "production",
        assistant: {
          stylesheet: "data:text/css;base64,LnZmcmMtd2lkZ2V0LS1sYXVuY2hlciB7CiAgICBsZWZ0OiAyNHB4ICFpbXBvcnRhbnQ7CiAgICByaWdodDogMCAhaW1wb3J0YW50Owp9CgoudmZyYy13aWRnZXQtLWNoYXQgewogICAgbGVmdDogMjRweCAhaW1wb3J0YW50OwogICAgcmlnaHQ6IDAgIWltcG9ydGFudDsKfQ=="
        }
      })
    }

    scoreAppContainer.appendChild(voiceflowScript)

    // Hide the chatbot on specific page
    const currentPath = window.location.pathname
    if (
      currentPath === "https://quandarycg.com/eventaiagent/" ||
      "https://quandarycg.com/eventaiagent"
    ) {
      const chatbotWidget = document.querySelector(".zsiqwidget")
      if (chatbotWidget) {
        chatbotWidget.style.display = "none" // Hide the chatbot
      }
    }

    return () => {
      // Cleanup: Remove scripts on unmount
      scoreAppContainer.removeChild(scoreScript)
      scoreAppContainer.removeChild(voiceflowScript)
    }
  }, [])

  return (
    <div
      id="score-app"
      style={{
        minHeight: "640px",
        position: "relative",
      }}
      data-sa-url="https://961ccac9-86a5-439e-a92c-dc620e91275b.scoreapp.com/?sa_hide_header=1&sa_hide_footer=1&sa_target=_top"
      data-sa-view="full"
    >
      {loading && <div>Loading...</div>}
    </div>
  )
}

function EventAiAgent(props) {
  return (
    <Layout location={props.location}>
      <SEO
        title="Event AI Agent | Quandary Consulting Group"
        description="With personalized insights, you'll get a clearer picture of how AI fits into your business – and where your tech stack may need an upgrade."
      />

      <section
        style={{
          padding: "5rem 0",
        }}
      >
        <ScoreApp />
      </section>
    </Layout>
  )
}

export default EventAiAgent
