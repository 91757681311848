import React, { useEffect } from "react"
import aiNGroup from "../images/AiN Group Logo.png"
import arrowIcon from "../images/arrow.png"
import lexiProfile from "../images/Lexi AI Agent.png"
import liveLearn from "../images/Live Learn 2025.png"
import QCGPrimaryLogo from "../images/QCG-Primary-logo-_1_.png"
import lexiBubble from "../images/Speech bubble mobine mockup.png"

import "./meetLexi.css"

function DesignElements() {
  return <>
    <div className="design-element-wrapper"></div>
  </>
}

function LexiAIHeader() {
  return <>
    <div className="meet-lexi-logo">
      <img
        src={aiNGroup}
        alt="AiN Group Logo"
        width={80}
        height={80}
        id="ain-group-logo"
      />
      <img
        src={liveLearn}
        alt="Live Learn 2025"
        width={120}
        height={80}
        id="live-learn-2025"
      />
    </div>
  </>
}

function LexiAIInfo() {
  return (
    <>
      <div className="meet-lexi-info">
        <div class="lexi-bubble-container">
          <img
            src={lexiBubble}
            alt="Lexi Bubble"
            width={320}
            height={320}
            id="lexi-bubble"
          />
          <div className="lexi-info-text">
            <h3>Hi, I'm Lexi, your Live & Learn AI agent!</h3>
            <p>
              I'm here to help you navigate the conference, find events, and
              make the most of your experience.
            </p>
          </div>
        </div>
        <div className="lexi-info">
          <img
            src={lexiProfile}
            alt="Ai Profile"
            width={120}
            height={120}
            id="ai-profile"
          />
          <a
            href="/eventaiagent"
            className="lexi-get-to-know-more"
            onClick={e => {
              e.preventDefault() // Prevent default navigation behavior
              if (window.location.pathname === "/eventaiagent") {
                // Force reload if already on the page
                window.location.reload()
              } else {
                // Navigate to the page if not already there
                window.location.href = "/eventaiagent"
              }
            }}
          >
            <img
              src={arrowIcon}
              alt="Arrow"
              width={24}
              height={24}
              id="arrow-icon"
            />
            <span>Get to Know Me More!</span>
          </a>
        </div>
      </div>
    </>
  )
}

function LexiAIEmbed() {
  useEffect(function loadVoiceFlow() {
    const script = document.createElement("script");
    script.src = "https://cdn.voiceflow.com/widget/bundle.mjs";
    script.async = true;

    script.onload = () => {
      window.voiceflow.chat.load({
        verify: { projectID: "6782ecab5b843c00ceda590c" },
        url: "https://general-runtime.voiceflow.com",
        versionID: "production",
        render: {
          mode: 'embedded',
          target: document.getElementById("embed-container"),
        },
        autostart: false,
      });
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div id="embed-container"></div>;
}

function LexiFooter() {
  return <>
    <div className="meet-lexi-footer">
      <h3>BROUGHT TO YOU BY</h3>
      <a href="/" className="footer-lexi-logo">
        <img
          src={QCGPrimaryLogo}
          alt="QCG Primary Logo"
          width={2458}
          height={564}
        />
      </a>
    </div>
  </>
}

function MeetLexi() {
  return <div className="meet-lexi-container">
    <DesignElements />
    <LexiAIHeader />
    <LexiAIInfo />
    <LexiAIEmbed />
    <LexiFooter />
  </div>
}

export default MeetLexi;
