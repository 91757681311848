/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react';

export const onClientEntry = () => {
    const excludePages = ['meetlexi', 'eventaiagent'];
    const pathname = window.location.pathname.replaceAll('/', '').toLowerCase();
    const shouldLoadScript = !excludePages.includes(pathname);

    console.log({ pathname, loadScript: shouldLoadScript });

    if (!shouldLoadScript) return;

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
        setTimeout(() => {
        var d = document;
        s = d.createElement('script');
        s.type = 'text/javascript';
        s.id = 'zsiqscript';
        s.defer = true;
        s.src = 'https://salesiq.zoho.com/widget';
        t = d.getElementsByTagName('script')[0]; t.parentNode.insertBefore(s, t);
        var loadTime = document.createElement('div');
        loadTime.id = "zsiqwidget";
        document.body.appendChild(loadTime);
                  },5000)
    `;

    document.body.appendChild(script);
};