import React from "react"
import Layout from "../components/layout.js"
import { Link } from "gatsby"
import Seo from "../components/seo.js"

import { LazyLoadComponent } from "react-lazy-load-image-component"

// import QuandaryConnectBanner from "../images/new-images/quandary-connect-banner.jpg"

import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Banner from "../components/hero/sam-banner.js"
import TechStack from "../components/hero/tech-stack.js"

import { AiOutlineRise } from "react-icons/ai"
import { RiTimerFlashLine } from "react-icons/ri"
import { BiLock } from "react-icons/bi"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

const SAM = loadable(() =>
  pMinDelay(import("../components/new-quandary/software-asset-mgmt"), 500)
)

const StepProcess = loadable(() =>
  pMinDelay(import("../components/new-quandary/4-steps-process-new.js"), 500)
)

const Testimonials2 = loadable(() =>
  pMinDelay(import("../components/swiper/testimonial-swiper.js"), 500)
)
const PartnerSwiper = loadable(() =>
  pMinDelay(import("../components/swiper/swiper-partner.js"), 500)
)
const Cases = loadable(() => pMinDelay(import("../components/cases.js"), 500))

class SoftwareAssetMgmtPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} banner={<Banner />}>
        <Seo
          Clear
          Data
          Insights
          at
          a
          Glance
          with
          Integrated
          Systems
          title="Software Asset Management | Quandary Consulting Group"
          description="Our Denver-based team provides custom integrations and workflow mapping that drives continuous process improvement for mid-market and enterprise."
        />
        <div className="page-content pt-0 pb-0">
          <div className="col-lg-8 mx-auto text-center">
            <h3 className="mb-3  text-cyan">Helping Firms Large and Small</h3>

            <p></p>
          </div>
          <PartnerSwiper />
          {/* <section
            style={{
              padding: "3rem 0",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12  text-center">
                  <h3 className="mb-3  text-cyan font-weight-bold">
                    We Identify, Monitor, and Reduce IT Overhead
                  </h3>
                  <p className="text-dark ">
                    How much does your business spend on unused software
                    licenses? Redundant apps?
                    <br /> Underutilized tools? Our audit helps you cut costs
                    without disrupting your business.
                  </p>
                  <Link
                    className="speak-to-btnx btn btn-primary btn-arrow mt-4"
                    to="/contact/"
                  >
                    Book Your Consultation
                  </Link>
                </div>
              </div>
            </div>
          </section> */}
          <TechStack />
          <section
            id="peaks-methodology"
            className="peaks"
            style={{
              padding: "3rem 0",
              background: "#ecf0f2",
              scrollMarginTop: "3.5rem",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-8 mx-auto text-center">
                  <h3 className="mb-3  text-cyan">
                    Easily Track, Manage, and Maximize Your Software
                  </h3>

                  <p></p>
                </div>
                <div className="col-lg-12 mt-3">
                  <SAM />
                </div>
              </div>
            </div>
          </section>
          <section
            style={{
              padding: "3rem 0",
            }}
          >
            <div className="col-lg-8 mx-auto text-center">
              <h3 className="mb-5  text-cyan font-weight-bold">
                Our 4-Step Process: Done in Weeks
              </h3>

              <p></p>
            </div>
            <LazyLoadComponent>
              <StepProcess />
            </LazyLoadComponent>
          </section>
          <section
            style={{
              padding: "3rem 0",
            }}
            className="bg-indigo"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12  text-center">
                  <h2 className="mb-3  text-white font-bold">
                    Where Does Your IT Budget Go?
                  </h2>
                  <p className=" text-white">
                    Reduce shadow IT. Tame tech sprawl. Consolidate
                    applications. Cancel unnecessary licenses. <br />
                    Negotiate better contracts. And get more resources for
                    exciting new IT projects.
                  </p>
                  <Link
                    className="speak-to-btnx btn btn-primary btn-arrow mt-4"
                    to="/contact/"
                  >
                    Evaluate Your Tech Stack
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section>
            <LazyLoadComponent>
              <Testimonials2 />
            </LazyLoadComponent>
          </section>

          {/* <section className="bg-gradient-gray py-5">
            <div className="container">
              <div className="intro text-center">
                <h3 className="m-0">
                  Put your business on autopilot with Quandary
                </h3>
              </div>
            </div>
          </section> */}
        </div>
      </Layout>
    )
  }
}

export default SoftwareAssetMgmtPage

export const softwareAssetMgmtPageQuery = graphql`
  query softwareAssetMgmtPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
