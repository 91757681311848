import React, { useEffect } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

function ScoreApp() {
    useEffect(function createStyle() {
        // Create a new style element
        const style = document.createElement("style");
        style.innerHTML = `
          .sa-full>iframe {
                padding-top: 3.5rem;

                @media screen and (min-width: 640px) {
                    padding-top: 6rem;
                }
            }
        `

        // Append the style element to the document's head
        document.body.appendChild(style)

        return () => {
            // Cleanup: Remove the style element on unmount
            document.body.removeChild(style)
        }
    }, []);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://static.scoreapp.com/js/integration/v1/embedding.js?v=FAi31h";
        script.async = true;

        const scoreAppContainer = document.getElementById("score-app");
        if (!scoreAppContainer) return;

        // Append the script to the body
        scoreAppContainer.appendChild(script);

        // Cleanup function to remove the script on unmount
        return () => {
            scoreAppContainer.removeChild(script);
        };
    }, []);

    return (
        <div
            id="score-app"
            style={{
                minHeight: "640px",
                position: "relative"
            }}
            data-sa-url="https://61d53b18-f804-466e-a8df-2b75a094cda2.scoreapp.com/?sa_hide_header=1&sa_hide_footer=1&sa_target=_top" data-sa-view="full">
        </div>
    )
}

function AiReadinessAssessment(props) {
    return (
        <Layout location={props.location}>
            <SEO
                title="AI Readiness Assessment | Quandary Consulting Group"
                description="With personalized insights, you'll get a clearer picture of how AI fits into your business – and where your tech stack may need an upgrade."
            />

            <section
                style={{
                    padding: "3rem 0",
                }}
            >
                <ScoreApp />
            </section>
        </Layout>
    )
}

export default AiReadinessAssessment;